<template>
  <p>Женское время</p>
</template>

<script>

export default {
  name: 'female-time',
  components: {
  }
}
</script>

<style>

</style>
