<template>
<div class="v-rules-page" id="rules-page">
  <div class="rules-position">
    <legend class="rules-zag">Общие положения</legend>
    <div class="rules">Настоящие правила утверждаются администрацией банного комплекса «Берлога», далее «Бани» и представляют собой публичную оферту, содержащую все существенные условия договора о возмездном оказании услуг между Баней и Посетителем.</div>
    <div class="rules">Каждый Посетитель обязан ознакомиться с данными правилами до начала пользования Баней. Пользование Баней означает принятие данных правил целиком, без каких-либо исключений. Посетитель, не согласный с данными правилами, обязан немедленно прекратить пользование Баней.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Условия посещения бани:</legend>
    <div class="rules">1. Режим работы общего отделения в соответствии с расписанием.</div>
    <div class="rules">2. Дети до 12 лет допускаются в сопровождении взрослых.</div>
    <div class="rules">3. Дети до 6 лет включительно посещение бесплатное.</div>
    <div class="rules">4. На детей до 12 лет приобретается детский билет.</div>
    <div class="rules">5. Мальчики старше 3 лет в женское отделение не допускаются.</div>
    <div class="rules">6. Администратор вправе отказать в посещении без объяснения причины.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Основные и дополнительные услуги:</legend>
    <div class="rules">Оказание услуг внутри Бани, расчеты за услуги производятся с применением персональных браслетов-чипов, выдаваемых на ресепшн при входе в Банный комплекс.</div>
    <div class="rules">Сохранность ценных вещей, документов и денег гарантируется Вашим персональным браслетом- чипом. Администрация не несет ответственности за сохранность вещей в персональной кабинке, если браслет -чип (являющийся ключом от персональной кабинки Посетителя) был утерей или снят с руки Посетителя и оставлен без присмотра не по вине сотрудников Бани.</div>
    <div class="rules">Администрация не несёт ответственность за оставленные без присмотра личные вещи в местах общего пользования: зал отдыха, моечное отделение, парная и раздевалки.</div>
    <div class="rules">Администрация Бани вправе отказать в обслуживании любому Посетителю в случае нарушения настоящих правил с обязательством по возврату оплаты за не оказанные услуги. При необходимости (по усмотрению администратора и по согласованию с гостями) возможно размещение двух посетителей бани в одной кабинке.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Запрещается!</legend>
    <div class="rules">1. Курить в помещениях бани.</div>
    <div class="rules">2. Приносить и распивать напитки, в том числе спиртные (Исключение: чай в термосе, вода).</div>
    <div class="rules">3. Приносить с собой продукты питания.</div>
    <div class="rules">4. Заносить в моечное отделение стеклянную тару.</div>
    <div class="rules">5. Стирать одежду в моечном отделении.</div>
    <div class="rules">6. Выносить тазы из моечного отделения.</div>
    <div class="rules">7. Запрещается после посещения парной купаться в плавательном бассейне без принятия душа.</div>
    <div class="rules">8. Находиться в помещении бани без банных тапочек.</div>
    <div class="rules">9. Клиентам женского отделения запрещается посещать бассейн без специальных шапочек для купания.</div>
    <div class="rules">10. Находиться в моечном помещении, бассейне и парной в домашнем нижнем белье.</div>
    <div class="rules">11. Находиться на полках парной в резиновой обуви.</div>
    <div class="rules">12. Проходить в парную с медом, масками для лица, глиной.</div>
    <div class="rules">13. Брить интимные места, стричь ногти. </div>
    <div class="rules">14. Производить расчет за оказанные услуги в любом месте и любому сотруднику Бани за исключением стойки кассира на стойке ресепшy по предъявлению персонального чипа-браслета.</div>
    <div class="rules">15. Выходить на улицу в одной простыни и тапочках</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Рекомендуется</legend>
    <div class="rules">- Не разговаривать громко в парилках и залах отдыха</div>
    <div class="rules">- Перед тем как поддать воду на печку спросить у остальных гостей</div>
    <div class="rules">- Париться и мыться, не доставляя неудобства другим Посетителям</div>
    <div class="rules">- Войдя в парную, не забираться сразу на верхнюю полку, где температура наиболее высокая. Чтобы привыкнуть к жару в парной, лучше всего 4-5 минут посидеть на нижней полке. Рекомендуемое время нахождения в парной не должно быть очень продолжительным: 2-4 захода в парную по 8 минут.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">НЕ рекомендуется посещение бани</legend>
    <div class="rules">- Посещать парную и хамам при сильном алкогольном опьянении</div>
    <div class="rules">- При беременности</div>
    <div class="rules">- При онкологических заболеваниях в любой стадии</div>
    <div class="rules">- При обострении хронических заболеваний любых органов и систем организма</div>
    <div class="rules">- При повышенной температуре</div>
    <div class="rules">- Больным активной формой туберкулеза</div>
      <div class="rules">- При склонности к кровотечениям и тромбозам</div>
    <div class="rules">- При выраженном атеросклерозе сосудов</div>
    <div class="rules">- Любые инфекционные заболевания кожи также являются противопоказанием к посещению сауны</div>
    <div class="rules">- Во время гриппа, ОРЗ, ангины и ОРВИ</div>
    <div class="rules">- Ишемическая болезнь сердца, эпилепсия, инфаркт миокарда, бронхиальная астма, гипертоническая болезнь II и III стадии. Люди с такими диагнозами составляют группу повышенного риска при посещении Бани.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Дополнения к правилам</legend>
    <div class="rules">- За утерю браслета с посетителей бани взимается штраф в размере 500 рублей</div>
    <div class="rules">- После 23.00 (основное время работы) действует специальный тариф на посещение бань. Дополнительное время начисляется в программе автоматически.</div>
    <div class="rules">- Время на оказание услуг (массаж, скрабирование, парение) не останавливается.</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'rules-page',
  components: {
  }
}
</script>

<style>
.v-rules-page{
  padding-left: 20px;
  padding-right: 20px;
  border: 5px solid #121820;
}
.rules-position{
    margin-right: 100px;
    width: 100%;
}
.rules-zag{
  font-size: 30px;
  margin-top: 10px;
}
.rules{
  text-align: justify;
  text-indent: 1.5em;
  margin-left: 20px;
  margin-top:10px;
  margin-bottom: 10px;
}
</style>
